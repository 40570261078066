import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/default/layout'
import SEO from '../components/seo'
import './404.scss'
import Wysiwyg from '../components/wysiwyg/Wysiwyg'

export default ({ data: { page } }) => (
    <Layout
        style={{ height: '100vh' }}
        className="NotFoundPage d-flex flex-column"
        footerProps={{ style: { paddingBottom: 50 } }}
    >
        <SEO
            yoast={page.yoast}
            title={page.title}
            description={page.content}
            dateModified={page.modified}
            datePublished={page.date}
        />
        <div className="background-container">
            <div className="circle focus-1" />
            <div className="circle focus-2" />
            <div className="circle focus-3" />
        </div>
        <main className="mt-auto mb-auto">
            <div className="container-fluid px-yd">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                        <Wysiwyg>{page.content}</Wysiwyg>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
)

export const pageQuery = graphql`
    query {
        page: wordpressPage(slug: { eq: "four-o-four" }) {
            slug
            title
            content
            ...YoastPage
        }
    }
`
